<!--
 * @Author: wuqi
 * @Date: 2020-12-10 10:08:09
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-16 18:09:41
 * @Description:
-->
<template>
  <div class="docs-admin-wrap">
    <card-header :title='menuData.name' :edit='edit' @deleteData='deleteData' @editTheme='editTheme'  :menuData='menuData'>
      <template #right v-if='!edit' >
        <div >
          <en-icon  @click.native="toMyFolder" class="header-right-arrow" name="shouqi"></en-icon>
        </div>
      </template>
    </card-header>
      <div class='equal-main'>
        <en-table :data="tableData" :loading='loading'  max-height="100%">
          <en-table-column type="index" width="60"></en-table-column>
          <en-table-column title="文件名称" prop="name" >
            <template slot-scope="scope">
              <div class="file-name">
                <en-icon
                  :name="suffix(scope.row.fileName).iconName"
                  :color="suffix(scope.row.fileName).iconColor"
                  size="small"
                ></en-icon>
                <span>{{ scope.row.fileName }}</span>
                <en-icon name="guding-wendangguanli" size="12px" color="#49bef2" style="margin-left:10px" v-if="(scope.row.top === '000')"></en-icon>
              </div>
            </template>
          </en-table-column>
          <en-table-column title="操作" field="attr" width="240">
            <template slot-scope="scope">
              <div class="appendix-btn" @click="multipleDownload([scope.row])">下载</div>
            </template>
          </en-table-column>
        </en-table>
    </div>
  </div>
</template>

<script>
import { foldersService } from "@/api/folders";
import folderComm from "@/mixins/myFolder/folderComm.js";// 获取同步的树,模板分类
import { downFileApi } from "@/api/downFileApi";
import { xhrDownload } from "@/tools/util";
import CardHeader from "./card-header";
// 附件弹窗
export default {
  name: "MyfolderCom",
  mixins: [folderComm],
  props: {
    menuData: {
      type: Object
    },
    edit: {
      default: false
    }
  },
  components: {
    CardHeader
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      const y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? `0${MM}` : MM;
      let d = date.getDate();
      d = d < 10 ? `0${d}` : d;
      let h = date.getHours();
      h = h < 10 ? `0${h}` : h;
      let m = date.getMinutes();
      m = m < 10 ? `0${m}` : m;
      let s = date.getSeconds();
      s = s < 10 ? `0${s}` : s;
      return `${y}-${MM}-${d} ${h}:${m}:${s}`;
    }
  },
  data() {
    return {
      loading: false,
      // 是否为编辑分类
      // 左侧菜单tree
      // 分页
      pageModel: {
        pageSize: 10,
        pageNo: 1,
        total: null,
        totalPages: null
      },
      // 文件列表
      docInfo: [],
      tableData: [],
      buttonData: [
        {
          name: "编辑",
          icon: "bianji-liebiao",
          iconColor: "#26c393"
        },
        {
          name: "置顶",
          icon: "zhiding-wendangguanli",
          iconColor: "#3e90fe"
        },
        {
          name: "删除",
          icon: "shibai",
          iconColor: "#f76b6b"
        }
      ],
      classifyItem: {} // 编辑分类数据
    };
  },
  mounted() {
    this.handleSearch();
  },
  methods: {
    // 搜索
    handleSearch() {
      this.queryFolderList("", 1, this.pageModel.pageSize, this.searchkey);
    },
    deleteData() {
      this.$emit("deleteData");
    },
    editTheme() {
      this.$emit("editTheme");
    },
    toMyFolder() {
      this.$router.push({
        path: "/my-folder/index"
      });
    },
    /**
     * @description: 获取下载地址
     * @param {filecode, type, filename}
     * @return:fileUrl
     */
    getFileUrl(filecode, type, filename) {
      let fileUrl = ""; // 文件地址
      const name = encodeURIComponent(filename) || "";
      const token = this.$store.getters.urlParam;
      if (type === "000") {
        fileUrl = `${token.showImageServer}?token=${token.showImageToken}&ts=${token.imgTs}&fileCode=${filecode}&appKey=${token.appKey}&attachmentName=${name}`;
      } else {
        fileUrl = `${token.getFileServer}?token=${token.getFileToken}&ts=${token.ts}&fileCode=${filecode}&appKey=${token.appKey}&attachmentName=${name}`;
      }
      return fileUrl;
    },
    /**
     * @description:多选下载
     * @param {type}
     * @return:
     */
    multipleDownload(row) {
      const _this = this;
      if (row.length > 10) {
        this.$message({
          message: "一次最多下载10个文件！",
          type: "warning"
        });
      }
      row.forEach(async(item) => {
        console.log(item);
        if (item.sourceType === 2) {
          await downFileApi.batchUpLoadFileZip({ id: item.id, type: 2 });
        } else {
          // 定义文件类型及跳转url变量
          let type = "";
          if (item.fileUrl.includes(".gif") || item.fileUrl.includes(".png") || item.fileUrl.includes(".jpg") || item.fileUrl.includes(".bmp")) {
            type = "000"; // 图片类型总和
          } else {
            type = "001"; // 文件类型总和
          }
          const src = _this.getFileUrl(item.fileUrl, type, item.fileName);
          xhrDownload(src, (error) => {
            this.$message.error(error);
          });
        }
      });
    },
    // 查询文件列表
    async queryFolderList(id, pageNo = 1, pageSize = 20) {
      try {
        this.loading = true;
        const res = await foldersService.queryMyFolderFiles({
          pageNo,
          pageSize
        });
        this.tableData = res.records;
        this.$nextTick(() => {
          console.log(this.$refs.ourtable);
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.docs-admin-wrap {
  height: 100%;
  .header-right-arrow{
    width: 18px;
    height: 18px;
  }
  .equal-main {
    position: relative;
    height: calc(100% - (20px + 30px))!important;
    clear: both;
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
  .file-name {
    span {
      margin-left: 5px;
    }
  }
  .appendix-btn {
    cursor: pointer;
    color: #3e90fe;
  }
  /deep/ .el-tree {
    .el-tree-node__label {
      font-size: 12px;
    }
  }
}

</style>
