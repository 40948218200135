var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "docs-admin-wrap" },
    [
      _c("card-header", {
        attrs: {
          title: _vm.menuData.name,
          edit: _vm.edit,
          menuData: _vm.menuData,
        },
        on: { deleteData: _vm.deleteData, editTheme: _vm.editTheme },
        scopedSlots: _vm._u(
          [
            !_vm.edit
              ? {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        [
                          _c("en-icon", {
                            staticClass: "header-right-arrow",
                            attrs: { name: "shouqi" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.toMyFolder.apply(null, arguments)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c(
        "div",
        { staticClass: "equal-main" },
        [
          _c(
            "en-table",
            {
              attrs: {
                data: _vm.tableData,
                loading: _vm.loading,
                "max-height": "100%",
              },
            },
            [
              _c("en-table-column", { attrs: { type: "index", width: "60" } }),
              _c("en-table-column", {
                attrs: { title: "文件名称", prop: "name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "file-name" },
                          [
                            _c("en-icon", {
                              attrs: {
                                name: _vm.suffix(scope.row.fileName).iconName,
                                color: _vm.suffix(scope.row.fileName).iconColor,
                                size: "small",
                              },
                            }),
                            _c("span", [_vm._v(_vm._s(scope.row.fileName))]),
                            scope.row.top === "000"
                              ? _c("en-icon", {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: {
                                    name: "guding-wendangguanli",
                                    size: "12px",
                                    color: "#49bef2",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("en-table-column", {
                attrs: { title: "操作", field: "attr", width: "240" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "appendix-btn",
                            on: {
                              click: function ($event) {
                                return _vm.multipleDownload([scope.row])
                              },
                            },
                          },
                          [_vm._v("下载")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }